@import './variables';
@import './typography';
@import './mixins';

body {
  margin: 0;
  padding: 0;
  background-color: $background;
  height: 100vh;
  -webkit-text-size-adjust: 100%; //fix for iOS
}

.no-scroll {
  overflow:  hidden !important;
}

#root {
  margin: 0;
  padding: 0;
  height: 100%;
} 
 
button {
  &:hover {
    cursor: pointer;
  }
}

/* Style Guide Components */

.button-{
  display: inline-block;
  height: 40px;
  border: none;
  border-radius: 4px;
  font-family: Museo-Sans-Rounded;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  padding-left: 16px;
  padding-right: 16px;

  &primary {
    @extend .button-;
    background-color: $purple;
    color: $white;
    &:hover {
      background-color: #3c4e8c;
      border-color: #384983;
    }
    &:focus {
      box-shadow: 0 0 0 3px rgba(71, 93, 167, 0.5);
    }
  }

  &secondary {
    @extend .button-;
    background-color: $white;
    border: 2px solid $teal;
    color: $indigo;
  }

  &tertiary {
    @extend .button-;
    background-color: $gray-200;
    color: $indigo;
  } &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(71, 93, 167, 0.25);
  }

  &teal {
    @extend .button-;
      background-color: $teal;
      color: $white;
    }
}


.form-input-label {
  font-family: Museo-Sans-Rounded;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $gray-800;
  margin-bottom: 4px;
  display: block;

  &-desc {
    @extend .form-input-label;
    font-size: 13px;
    color: $gray-700;
  }
}

.form-input {
  background-color: $white;
  border: 2px solid $gray-400;
  border-radius: 4px;
  width: 100%;
  &:focus {
  border: solid 2px #3db28c !important;
  outline: none;
  }
  &:hover {
    border: solid 2px #3db28c;
  } 
}

.form-input-dropdown {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: 2px solid $gray-400;
  background-color: #ffffff;
  padding-left: 8px;
  padding-right: 8px;
  font-family: Museo-Sans-Rounded;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $gray-800;
  display: block;
}

.tool-tip {
  border-radius: 8px;
  background-color: $dark;
  opacity: 1 !important;
  margin-bottom: 8px;

  .tooltip-inner {
    @include font-source(13px, $white, $font-weight-semibold);
    background-color: $dark;
  }

  .arrow::before {
    border-top-color: $dark !important;
  }
}

/* Style Guide Components end */

.mainWrap {
  min-height: 100%;
  padding-bottom: 440px;
}

.rectangle {
  width: 100%;
  box-shadow: 1px 1px 3px 0 #00000017;
  background-color: $white;
  padding: 24px;
}
 
.rectangleWithBorder {
  width: 100%;
  background-color: #ffffff;
  padding: 24px;
  border: solid 1px #e2e2e2;
}

.mb-xs {
  margin-bottom: 0.15rem;
}

.subHeader {
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.09);
  background-color: $white;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 1px;
}

.entryBox {
  width: 100%;
  box-shadow: 1px 1px 3px 0 #00000017;
  background-color: $white;
  padding: 30px;
  margin-bottom: 1px;
}

.padding-28 {
  padding: 28px !important;
}

.padding-left-4 {
  padding-left: 4px !important;
}
.padding-right-4 {
  padding-right: 4px !important;
}

.padding-left-20 {
  padding-left: 20px !important;
}


.nav-tabs {
  .nav-item {
    margin-bottom: 0px !important;
  }

  .nav-link {
    border: none !important;
  }

  .active {
    border-bottom: 4px solid #3db28c !important;
    border-radius: 0 !important;
  }
}


a.nav-item.nav-link {
  color: $gray-700 !important;
}

a.nav-item.nav-link.active {
  color: #2d2d2d !important;
  font-weight: bold !important;
}

.comingSoonBadge {
  display: inline-block;
  background-color: $teal;
  padding: 5px 10px;
  border-radius: 16px;
  color: $white;
  font-family: museo-sans-rounded;
  font-size: 14px;
  font-weight: 500;
}

.badge {
  display: inline-block !important;
  padding: 2px 8px 3px 8px;
  border-radius: 16px !important;
  font-family: museo-sans-rounded !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 300 !important;
  margin-right: 4px !important;
  margin-bottom: 4px !important;

  &-tool {
    @extend .badge;
    background-color: $indigo;
    color: $white !important;
  }

  &-dataset {
    @extend .badge;
    background-color: $teal;
    color: $white !important;
  }

  &-project {
    @extend .badge;
    background-color: $orange;
    color: $white !important;
  }

  &-paper {
    @extend .badge;
    background-color: $duck-egg;
    color: $gray-800 !important;
  }

  &-course {
    @extend .badge;
    background-color: $indigo;
    color: $white !important;
  }

  &-phenotype {
    @extend .badge;
    background-color: $orange-light;
    color: $gray-800 !important;
    font-weight: 500 !important;
  }

  &-tag {
    @extend .badge;
    background-color: $gray-light;
    color: $gray-800 !important;
  }

  &-version {
    @extend .badge;
    background-color: $gray-light;
    color: $gray-800 !important;
    span:first-child{
      padding-right: 6px;
      border-right: 1px solid $gray-400;
     }
    span:last-child{
      padding-left: 5px;
    }
  }

  &-comingSoon {
    @extend .badge;
    background-color: $orange-light;
    color: $gray-dark !important;
    font-weight: bold;
    font-size: 16px;
  }

  &-notRated { 
    @extend .badge;
    background-color: $orange-light;
    color: $med-black !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    margin: 0px !important;
  }
}

.iconHolder {
  text-align: right;
  padding-top: 5px;
}

.reviewTitleGap {
  margin-left: 10px;
  margin-right: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $background inset !important;
  box-shadow: 0 0 0 30px $background inset !important;
}

input[type='checkbox']:after {
  width: 24px;
  height: 24px;
  border: solid 2px #d6d6d6;
  background-color: $white;
  content: '';
  display: inline-block;
  visibility: visible;
  border: solid 2px #d6d6d6;
}

input[type='checkbox']:checked:after {
  width: 24px;
  height: 24px;
  border: solid 2px #d6d6d6;
  background-color: $white;
  position: relative;
  // padding-left: 3px;
  content: url(../images/wee-square.svg);
  display: inline-block;
  visibility: visible;
  border: solid 2px #d6d6d6;
  padding-top: 2px;
}

input[type='radio']:after {
  width: 23px;
  height: 23px;
  border-radius: 23px;
  top: -4px;
  left: -2px;
  position: relative;
  background-color: $white;
  content: '';
  display: inline-block;
  visibility: visible;
  border: solid 2px #d6d6d6;
}

input:active {
    border: 0 !important
}

input[type='radio']:checked:after {
    position: relative;
    width: 23px;
    height: 23px;
    padding-left: 3px;
    padding-top: 1px;
    @include rounded(23px);
    background-color: $white;
    content: url(../images/radio.svg);
    display: inline-block;
    visibility: visible;
    border: solid 2px #d6d6d6;
}

.modal-body input[type='radio']:after {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  top: -4px;
  left: -2px;
  position: relative;
  background-color: $white;
  content: '';
  display: inline-block;
  visibility: visible;
  border: solid 2px #d6d6d6;
}

.modal-body input[type='radio']:checked:after {
  width: 24px;
  height: 24px;
  @include rounded(24px);
  padding-left: 4px;
  padding-bottom: 20px;
  position: relative;
  background-color: $white;
  content: url(../images/radio.svg);
  display: inline-block;
  visibility: visible;
  border: solid 2px #d6d6d6;
}

.tabsBackground {
  width: 100%;
  background-color: $white !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.09);

  &-shadow-bottom {
    @extend .tabsBackground;
    box-shadow: 0 4px 2px -2px rgba(0,0,0,0.09)
  }
}

.tab-content {
  margin-top: 1px;
}

.verticalNavBarHolder {
  display: block !important;
}

.verticalNavBar {
  padding: 15px 10px 15px 30px;
}

.verticalNavBar.active {
  background-color: #f6f7f8;
  border-right: 4px solid $teal !important;
}

svg:not(:root) {
  overflow: visible !important;
}

.svg-16 {
  width: 16px;
  height: 16px;
}

.svg-19 {
  width: 19px;
  height: 19px;
}

.svg-20 {
  float: left;
  width: 20px;
  height: 20px;
}

.svg-24 {
  float: left;
  width: 24px;
  height: 24px;
}

.badgeSvg {
  width: 12px;
  height: 12px;
}

.addButton {
  font-size: 14px !important;
}

.cancelButton {
  width: 82px;
  height: 40px;
}

.publishButton {
  width: 79px;
  height: 40px;
}

.linkButton {
  text-decoration: underline;
  white-space: nowrap;
}

.linkButton:hover {
  color: #fff;
}

.is-invalid ~ .invalid-feedback {
  display: block;
}

textarea:focus {
  border: solid 2px $teal !important;
}

.form-group .addFormInput {
  font-family: museo-sans-rounded;
  font-size: 13px;
  font-weight: 500;
  color: $gray-700;
  width: 100%;
  height: 40px;
  @include rounded(4px);
  border: solid 2px $gray-400;
  background-color: $white;
}

.form-group .addFormInput:focus {
  @include rounded(4px);
  border: solid 2px $teal;
}

.form-group .emptyFormInput,
.form-group .emptyFormInput:focus {
    @include rounded(4px);
    border: solid 2px $red;
}

.form-group .addFormInputTypeAhead {
  width: 100%;
  height: 40px;
  @include rounded(4px);
  border: solid 2px $gray-400;
  background-color: $white;
}

.form-group .addFormInputTypeAhead:focus-within {
    @include rounded(4px);
    border: solid 2px $teal !important;
}

.form-group .emptyFormInputTypeAhead,
.form-group .emptyFormInputTypeAhead:focus-within {
    @include rounded(4px);
  border: solid 2px $red;
}

.rbt-input-main.form-control,
.rbt-input-main.form-control:focus {
  border: solid 0px $white !important;
}

.rbt-input-multi.form-control,
.rbt-input-multi.form-control:focus {
  border: solid 0px $white !important;
}

.hdfeedback {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #dc3545;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
  width: 100%;
}

.react-datepicker__input-container input {
  padding: 0.5rem 0.75rem;
  max-width: 480px;
  font-size: 1rem;
  line-height: 1.25;
  width: 100%;
  color: $gray-700;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0.15);
  @include rounded(0.25rem);
}

.react-datepicker__input-container input:focus {
  border: solid 2px $teal;
}

/* RBTS ARE THE STYLES FOR TYPEAHEAD INPUTS */
.rbt-input {
  font-family: museo-sans-rounded !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: $gray-700 !important;
}

.rbt-input-multi {
  font-family: museo-sans-rounded !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: $gray-700 !important;
  width: 100% !important;
  border-radius: 4px !important;
  border: solid 2px $gray-400 !important;
  background-color: $white !important;
}

.rbt-input-multi.focus {
  border-color: $teal !important;
  box-shadow: none !important;
}

.rbt-input-multi.after.focus {
  border-color: $red !important;
  box-shadow: none !important;
}

.rbt-token {
  background-color: #f3f3f3 !important;
  color: $gray-800 !important;
  border-radius: 16px;
}

.rbt-close {
  color: #979797 !important;
}

.descriptionInput {
  height: 186px !important;
  font-family: museo-sans-rounded !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: $gray-700 !important;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-outline-secondary {
  color: #2d2d2d !important;
  font-size: 14px !important;
  background-color: $white !important;
  background-image: none;
  border-color: $gray-400 !important;
}

.btn-outline-secondary:active,
.btn-outline-secondary.active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #2d2d2d !important;
  font-size: 14px !important;
  background-color: $white !important;
  background-image: none;
  border-color: $gray-400 !important;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0px !important;
}

.btn-info {
    color: #2d2d2d !important;
    font-size: 14px !important;
    background-color: $gray-300 !important;
    background-image: none;
    border-color: $gray-300 !important;
  }
  
  .btn-info:active,
  .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #2d2d2d !important;
    font-size: 14px !important;
    background-color: $gray-300 !important;
    background-image: none;
    border-color: $gray-300 !important;
  }
  
  .btn-info:focus,
  .btn-info.focus {
    box-shadow: 0 0 0 0px !important;
  }

.dropdown-menu {
  border: 1px solid $gray-400 !important;
}

.dropdown-industry {
  border: 1px solid $gray-400 !important;
  width: 100%
}

.techDetailButton {
  height: 40px;
  width: 140px;
  border: 2px solid $teal !important;
  border-radius: 4px !important;
  background-color: $white;
  color: $indigo;
  font-family: museo-sans-rounded;
  font-size: 14px !important;
}

.accountDropDownGap {
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  .nav {
    padding-left: 2px;
    padding-right: 2px;
  }
  .nav a {
    display: block !important;
    width: 100%;
    margin: 0px;
  }
  .nav a.active {
    border: 1px solid $teal !important;
    border-radius: 0px;
    margin: 0px;
  }
}

.rwt__tab {
  color: $gray-800 !important;
  background-color: #fff !important;
  border: 0 !important;
  font-family: museo-sans-rounded !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  padding: 1rem 2rem !important;
}

.rwt__tab[aria-selected='true']:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 4px solid $teal !important;
  background-color: #f6f7f8 !important;
  background: solid !important;
}

.rwt__tab:focus {
  background-color: #f6f7f8 !important;
}

.avatar-circle {
  width: 32px;
  height: 32px;
  background-color: $teal;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.initials {
  position: relative;
  top: 5px; /* 25% of parent */
  font-size: 16px; /* 50% of parent */
  line-height: 16px; /* 50% of parent */
  color: #fff;
  font-weight: bold;
}

.formBottomGap {
  height: 240px;
}

iframe#discourse-embed-frame {
  min-height: 500px;
  height: 100%;
  overflow-y: scroll;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  font-size: 28px;
  font-weight: bold;
  padding-right: 30px;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

// .modal-body {
//   text-align: left;
//   padding: 2px 16px;
// }

.btn-light {
  background-color: #e7e9ed !important;
}

.descriptionWhiteSpace {
  white-space: pre-wrap;
}

.searchTabsHolder {
  margin-top: 2px;
}

.pagination {
  margin-top: 20px;
}

.page-item.active .page-link,
.page-item.active .page-link:hover {
  background-color: var(--purple);
  color: $white !important;
}

.page-link {
  font-family: museo-sans-rounded !important;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal;
  color: var(--gray-700) !important;
  display: block !important;
  border: 0px !important;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.09) !important;
  padding: 12px 10px !important;
}

.page-link:focus {
  background-color: $white !important;
}

.page-item {
  margin: 0px 1px !important;
  width: 32px !important;
  text-align: center;
}

.page-item.active .page-link:hover {
  background-color: rgba(71, 93, 167, 0) !important;
  color: $white !important;
}

.page-link:hover {
  background-color: rgba(71, 93, 167, 0.3) !important;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.page-item:last-child,
.page-item:first-child {
  width: auto !important;
}

.overflowWrap {
  overflow-wrap: break-word;
}

.flex-form {
  display: flex;
  flex-direction:row;
}

.flex-form .scrollable-sticky-column {
  overflow-y: auto;
  overflow-x: hidden;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.icon-18 {
  width: 18px;
  height: 18px;
}

.form-control:focus {
  border: solid 2px $teal !important;
}

.radio {
  display: inline-block !important;
  margin-right: 1em !important;
  margin-left: 1em !important;
  min-width: 5%;
}

.radio #principleInvestigator-label {
  width: 100%;
}

.radio #principleInvestigator-label input[type='radio'] {
  margin-right: 30%;
}

.flex-grow {
  flex: 1;
}

.fillPage {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.action-bar {
  display: flex;
  flex-flow: row nowrap;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 72px;
  background-color: $white;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.09);
  z-index:1000;
}

.hidden {
  display: none;
}

.wayfinder-container {
  margin-top: 10px;
  padding: 20px !important;
  border: solid 1px #d6d6d6;
  border-radius: 5px;
}

.navBarGap {
  height: 79px;
}

.metadataQuality {
  padding-left: 12px;
  padding-right: 12px;
  height: 25px;
  line-height: 25px;
  display: inline-block;
  color: white;
  font-size: 14px;
  @include rounded(4px);
}

.notRatedBackground {
  color: $gray-800;
  font-weight: 500;
  opacity: 0.7;
  padding-left: 0px;
}

.ratingSilverBackground {
  background-image: linear-gradient(to left, #cbcbcb, #9b9b9b 50%);
}

.ratingBronzeBackground {
  background-image: linear-gradient(to left, #d9a466, #b16a36 50%);
}

.ratingPlatinumBackground {
  background-image: linear-gradient(to left, #cbcbcb, #9b9b9b 50%);
}

.ratingGoldBackground {
  background-image: linear-gradient(to left, #dfca5d, #bb9a30 50%);
}

.selectedBorder {
  border: solid 4px $teal !important;
}

.pixelGapTop {
  margin-top: 1px;
}

.pixelGapRight {
  margin-right: 1px;
}

.pixelGapBottom {
  margin-bottom: 1px;
}

.pixelGapLeft {
  margin-left: 1px;
}

.footerButton {
  min-width: 145px;
  min-height: 40px;
  border-radius: 4px;
  border: solid 2px #ffffff;
  font-size: 14px;
  display: inline-block;
  line-height: 40px;
  text-align: center;
}

.footerButtonContainer {
    text-align: right;
}

@media (max-width: 991px) {
    .social-links{
        text-align: right;
        font-size: 0 !important;
    }
    .link-social {
        padding-left: 2px;
    }
}


.text-size-small {
  font-size: 14px;
}
.text-color-lightest {
  color: $white;
}
.text-color-lightest :hover {
  color: $white;
} 
.footerLogo {
  height: 60px;
}

.icon-social {
  width: 17px;
  margin-right: 8px;
} 
 
.floatRight{
  @include mq('tablet-small'){
    float: right;
  }
}

.floatLeft{
    @include mq('tablet-small'){
        float: left;
    }
}

.inlineBlock {
    display: inline-block
}

.pointer, .btn {
    cursor: pointer;
}

.flipSVG{
  transform: scaleY(-1) !important;
}

button a {
  cursor: pointer !important;
}

.adFilters {
    box-shadow: 1px 1px 3px 0 #00000017;
    background-color: $white;
    padding: 10px;
    max-height: 180px;
    overflow-y: scroll;
}

/* 
  #
  #
  Bootstrap overrides below
  #
  #
  */
  
  .row .noMargin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .alignCenter {
    align-content: center;
  }

  .alignSelfCenter{
    align-self: center;
  }

  .noPadding{
    padding: 0px !important;
  }

  .pad-top-32 {
    padding-top: 32px !important;
  }

  .pad-top-24 {
    padding-top: 24px !important;
  }

  .pad-top-18 {
    padding-top: 18px !important;
  }

  .pad-top-16 {
    padding-top: 16px !important;
  }

  .pad-top-8 {
    padding-top: 8px !important;
  }

  .pad-bottom-0 {
    padding-bottom: 0 !important;
  }

  .pad-bottom-2 {
    padding-bottom: 2px !important;
  }

  .pad-bottom-4 {
    padding-bottom: 4px !important;
  }

  .pad-bottom-8 {
    padding-bottom: 8px !important;
  }

  .pad-bottom-16 {
    padding-bottom: 16px !important;
  }

  .pad-bottom-24 {
    padding-bottom: 24px !important;
  }

  .pad-bottom-32 {
    padding-bottom: 32px !important;
  }

  .margin-top-32 {
    margin-top: 32px !important;
  }


  .margin-top-24 {
    margin-top: 24px !important;
  }

  .margin-top-20 {
    margin-top: 20px !important;
  }

  .margin-top-18 {
    margin-top: 18px !important;
  }

  .margin-top-16 {
    margin-top: 16px !important;
  }

  .margin-top-8 {
    margin-top: 8px !important;
  }

  .margin-top-6 {
    margin-top: 6px !important;
  }

  .margin-top-2 {
    margin-top: 2px !important;
  }

  .margin-left-6 {
    margin-left: 6px !important;
  }

  .margin-left-8 {
    margin-left: 8px !important;
  }

  .margin-left-10 {
    margin-left: 10px !important;
  }

  .margin-left-15 {
    margin-left: 15px !important;
  }

  .margin-left-16 {
    margin-left: 16px !important;
  }

  .margin-right-1 {
    margin-right: 1px !important;
  }

  .margin-right-8 {
    margin-right: 8px !important;
  }

  .margin-right-15 {
    margin-right: 15px !important;
  }
  
  .margin-right-16 {
    margin-right: 16px !important;
  }

  .margin-right-30 {
    margin-right: 30px !important;
  }

  .margin-bottom-0 {
    margin-bottom: 0px !important;
  }

  .margin-bottom-6 {
    margin-bottom: 6px !important;
  }

  .margin-bottom-12 {
    margin-bottom: 12px !important;
  }

  .margin-bottom-8 {
    margin-bottom: 8px !important;
  }

  .margin-bottom-16 {
    margin-bottom: 16px !important;
  }

  .margin-bottom-48 {
    margin-bottom: 48px !important;
  }

  .col .noPadding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col .pad-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .pad-left-0 {
    padding-left: 0px !important;
  }

  .pad-left-8 {
    padding-left: 8px !important;
  }

  .pad-left-14 {
    padding-left: 14 !important;
  }

  .pad-left-16 {
    padding-left: 16 !important;
  }

  .pad-right-8 {
    padding-right: 8px !important;
  }

  .col .pad-left-8 {
    padding-left: 8px !important;
  }

  .col .pad-left-24 {
    padding-left: 24px !important;
  }

  .pad-left-24 {
    padding-left: 24px !important;
  }

  .col .pad-right-24 {
    padding-right: 24px !important;
  }

  .pad-right-24 {
    padding-right: 24px !important;
  }

  .pad-right-0 {
    padding-right: 0px !important;
  }

  .width-100{
    width: 100% !important;
  }

  .height-16{
    height: 16px !important;
  }

.collection-card {
  color: $gray-700;
  font-size: 13px;
  font-weight: 500;
  &-user {
    @extend .collection-card;
  }
  &-updated {
    @extend .collection-card;
    float:right;
  }
}

.col .widthAuto {
  width: auto !important;
  flex-grow: 0 !important;
}

.dataClassX {

  @include mq('tablet-small',max){
    transform: translate(-20px, 0px) !important;
  }

  @include mq('phablet',max){
    transform: translate(375px, -30px) !important;
  }

  @include mq('phone-wide',max){
    transform: translate(215px, -30px) !important;
  }

  @include mq('phone',max){
    transform: translate(-20px, 5px) !important;
  }

}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  align-items: center;
  right: 66%;
  left: 34%;
  z-index: 999999;
  width: max-content;
  padding: 10px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: hidden;
}

.notification {
  box-sizing: border-box;
  padding: 12px 15px 12px 48px;
  border-radius: 2px;
  color: $black;
  background-color: #ccc;
  box-shadow: 0 0 12px #999;
  cursor: pointer;
  font-size: 1em;
  line-height: 2.0em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
  border-top-style: solid;
  border-top-color: $red;
  border-top-width: 8px;
  .title {
    font-size: 1em;
    line-height: 1.2em;
    font-weight: bold;
    margin: 0 0 0 0;
  }
  &:hover, &:focus {
    opacity: 1;
  }
} 

.notification-enter {
  visibility: hidden;
  transform: translate3d(0, 100%, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(0, 0%, 0, 100%);
  transition: all 0.4s;
}
 
.notification {
  &:before {
    position: absolute;
    top: 32.5%;
    left: 15px;
    margin-top: -14px;
    display: block;
    font-family: 'Notification';
    width: 28px;
    height: 28px;
    font-size: 28px;
    text-align: center;
    line-height: 28px;
  }
}

.notification-warning {
  background-color: $white;
  &:before {
    content: url(../images/attention.svg);
    margin-top: -18px;
    margin-left: 0px;
  }
}

.addToCollectionButton {
  width: 147px;
  height: 40px;
}

.addToCollectionButton:focus {
  box-shadow: none !important;
}

.textUnderline {
  text-decoration: underline;
}      

.collectionCheckSvg { 

  float: right;

  @include mq("tablet-small",max) {
    transform: translate(-5px, 0px) !important;
  }
  @include mq("phablet",max) {
    transform: translate(0px, -20px) !important;
  }
}

.collectionCheckSvg g {
  fill: #2C8267;
}

@media (max-width: 576px) {

  .closeDataClass{
    transform: translate(90%, -100%);
  }

}

.plusMinusButton {
  width: 32px;
  height: 32px;
  background-color: $gray-light;
  border-radius: 16px;
  border: 0px;
  display: inline-block;
  text-align: center;
  line-height: 32px;
  color: $plus-minus;
  margin-right: 4px;
  font-size: 25px;
}

.plusMinusButton:hover:enabled {
  background-color: $plus-minus;
  color: $gray-light
}

.plusMinusButton:disabled {
  opacity: 0.2;
}

.ellipsis {
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accountClassToolTip{
  width: 275px;
  border-radius: 8px;
  background-color: $dark;
  padding: 10px;
  position: absolute;
  line-height: 14px;
  left: -140px;
  top: -50px;
  z-index: 1;
}

.accountSectorToolTip{
  top: -42px;
}
.buttonHeight40 {
  height: 40px;
}

.centerText{
  text-align: center;
}

.line-height-normal {
  line-height: normal;
}

.hiddenSelectBox {
  opacity: 0;
  width: 100%;
  cursor: pointer;
}

.sectorValueInFront {
  z-index: 2;
  width: 100%;
}

.sectorValue {
  position: absolute;
  z-index: 1;
  top: 13px;
  left: 15px;
}

.checker {
	width: 20px !important;
	margin-left: 15px;
	margin-right: 12px;
} 