@import '../../../css/variables';

.greyCancelButton {
    min-width: 83px;
    height: 40px;
    background-color: $gray-200;
  }

.smallAddButton {
  width: 156px;
  height: 40px;
}

#addReviewButton {
  width: 100%;
  background-color: #e7e9ed;
  height: 40px;
} 