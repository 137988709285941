@import '../../css/variables';
@import '../../css/typography';
@import '../../css/mixins';

.section-header {
	cursor: pointer;
	margin-left: 33px;
	margin-top: 8px;
	margin-bottom: 16px;
	font-size: 16px;
}

.section-not-inreview {
	color: $gray-600 !important;
}

.section-header-active {
	cursor: pointer !important;
	opacity: 1 !important;
	margin-left: 33px;
	margin-top: 0px;
	margin-bottom: 0px;
	font-size: 16px;
	font-weight: 700;
}

.section-subheader {
	margin-left: 33px;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 12px !important;
	border-left: 1px solid $gray-400;
}

.section-subheader li:first-child {
	margin-top: 8px;
}

.section-subheader li:last-child {
	margin-bottom: 8px;
}

.dar-nav-item + .dar-nav-item {
	padding-top: 8px;
}

.nav-item-active {
	font-weight: bold !important;
}

.active-border {
	border-left: 4px solid $teal;
	padding-top: 8px;
	padding-bottom: 8px;
}

.active-border > div {
	margin-left: -4px;
}

.active-grey-border {
	border-left: 1px solid $gray-400;
}

.dar-tabsBackground {
	width: 100%;
	background-color: $white !important;
	align-items: center !important;
	justify-content: center !important;
	margin-left: 1px !important;
}

.banner {
	background-color: $indigo;
	width: 100%;
	min-height: 70px;
	margin-left: 0px !important;
	align-content: center;
}

.banner .banner-left {
	vertical-align: middle;
	padding-left: 32px !important;
}

.banner .banner-right {
	text-align: right;
	vertical-align: middle;
	padding-right: 32px !important;
	svg {
		margin-left: 20px;
		cursor: pointer;
	}
}

#darLeftCol {
	width: calc(25vw - 15px);
	max-height: calc(100vh - 70px);
	height: min-content;
	margin-right: 15px;
	margin-top: 24px;
	padding-top: 24px;
}

#darCenterCol {
	width: calc(41.66vw - 15px);
	margin-left: 15px;
	padding-top: 24px;
}

#darCenterCol.extended {
	width: calc(58.33vw - 15px);
}

#darRightCol {
	max-height: calc(100vh - 79px);
	height: min-content;
	width: calc(33.33vw - 30px);
	padding-top: 24px;
	margin-right: 30px;
}

#darDropdownNav {
	display: none;
}

#darDropdownNav select {
	width: 100%;
	height: 40px;
	background-color: #ffffff;
	border: solid 2px $gray-400;
	border-radius: 4px;
	font-family: museo-sans-rounded;
	font-weight: 500;
	color: $gray-800;
}

.darTab {
	padding: 15px 0;
	background-color: $white;
	max-height: calc(100vh - 169px);
	margin-left: 1px;
}

@media (min-width: 769px) and (max-width: 1160px) {
	.banner {
		height: 70px !important;
	}

	.darTab {
		max-height: calc(100vh - 208px);
	}
}

@media (min-width: 1161px) {
	#darRightCol {
		max-height: calc(100vh - 79px);
	}

	.darTab {
		max-height: calc(100vh - 169px);
	}
}

@media (max-width: 768px) {
	#darLeftCol,
	#darRightCol {
		display: none !important;
	}

	#darDropdownNav {
		display: block;
		width: 100%;
		margin-bottom: 16px;
	}

	#darCenterCol,
	#darCenterCol.extended {
		width: calc(100vw - 15px);
		margin-right: 15px;
	}
}

@media (min-width: 884px) {
	.banner .banner-right span,
	.banner-right a,
	.banner-right button {
		line-height: 30px;
	}
}

.darIframe {
	height: 600px;
	width: 800px;
}

.ck-button {
	margin: 4px;
	background-color: #efefef;
	border-radius: 4px;
	border: 1px solid #d0d0d0;
	overflow: auto;
	float: left;
}

.ck-button label {
	float: left;
	width: 4em;
}

.ck-button label span {
	text-align: center;
	padding: 3px 0px;
	display: block;
	border-radius: 4px;
}

.ck-button label input {
	position: absolute;
	top: -20px;
}

.ck-button input:hover + span {
	background-color: #efe0e0;
}

.ck-button input:checked + span {
	background-color: #911;
	color: #fff;
}
.ck-button input:checked:hover + span {
	background-color: #c11;
	color: #fff;
}

/* Winterfell Form CSS */

.rbt-input-multi.form-control[disabled] {
	background: #eee !important;
}

input[type=text]:disabled {
		background-color: #eee !important;
	
}

input[type=text]:disabled,
input[type=checkbox]:disabled,
input[type=radio]:disabled {
	&:after {
		background-color: #eee !important;
	}
}

.dar {
	&__header {
		padding: 24px;

		p {
			margin-bottom: 0;

			&:first-child {
				margin-bottom: 8px !important;
			}
		}
	}

	&__questions {
		margin-bottom: 36px;
		padding: 24px 0;
		margin-top: 1px;
		box-shadow: 0 1px 6px -5px #333;
	}

	&__questions .col-md-12 {
		padding: 0px !important;
		padding-left: 8px !important;
		padding-right: 8px !important;
	}

	// &__questions .form-group {
	// 	padding-left: 16px !important;
	// 	padding-right: 16px !important;
	// }

	&__questions .card-body .form-group {
		padding-left: 0 !important;
		padding-right: 0 !important;
		margin-bottom: 0 !important;
	}

	&__check {
		list-style: none;
		margin-left: 24px;
		label {
			padding: 5px;
			> input[type='checkbox'] {
				margin-right: 10px;
				width: 24px;
				height: 24px;
				cursor: pointer;
				text-align: center;
				vertical-align: middle;
				margin-left: -30px;
			}
		}
	}
	&__radio {
		&--item {
			label {
				padding: 5px;
				> input[type='radio'] {
					-moz-appearance: none;
					-webkit-appearance: none;
					appearance: none; /* in case this ever gets supported */
					background: #fff;
					border-radius: 100%;
					border: 1px solid transparent;
					display: inline-block;
					cursor: pointer;
					text-align: center;
					top: 5px;
					width: 20px;
					height: 20px;
					margin-right: 10px;
					vertical-align: middle;
					transition: all 250ms ease;
					&:focus {
						border-color: transparent;
						outline: none;
					}

					&:checked {
						border-color: #fff;
						transition: all 250ms ease;

						&:after {
							padding-left: 1px;
							padding-top: 3px;
							@include rounded(100%);
						}
					}
				}
			}
		}
		&--list {
			list-style: none;
		}
	}
}

.action-bar {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	box-sizing: border-box;
	position: sticky !important;
	margin-bottom: -30px;
	padding: 0 24px;
	left: 0;
	bottom: 0;
	width: 100%;
	min-height: 72px;
	background-color: $white;
	box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.09);
	z-index: 1000;

	&-status {
		display: inline-block;
	}

	&--questions {
		flex: 2 0 0;
		@include font-source(14px, $gray-800, $font-weight-semibold);
		p {
			margin-bottom: 0;
		}

		.sla {
			width: max-content;
			display: inline-block;
			margin-right: 24px;
		}
	}

	&-actions {
		display: flex;
		justify-content: flex-end;
		flex: 1 0 0;
		margin-left: auto;
		align-items: center;

		& > * {
			margin-right: 6px;
		}
	}

	&-actions > button {
		margin-right: 8px;
	}

	&-actions > button:last-child {
		margin-right: 0px;
	}
}

.amendment-count {
	@include font-source(14px, $gray-800, $font-weight-semibold);
}

.userOption {
	> div:nth-of-type(2) {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		span {
			@include font-source(14px, $gray-600, $font-weight-semibold);
		}
	}
}

.datasetName {
	@include font-source(14px, $gray-800, $font-weight-semibold);
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.datasetDescription {
	@include font-source(14px, $gray-600, $font-weight-semibold);
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.pad-1 {
	padding-right: 1px !important;
}

.dar-form {
	padding: 0 10px;
	h3 {
		white-space: pre-line;
		padding: 0 15px;
		@include font-source(14px, $gray-dark, $font-weight-semibold);
		line-height: 21px;
		white-space: pre-line;
	}

	h4 {
		margin-bottom: 0;
		padding: 16px 16px;
		@include font-source(17px, $gray-dark, $font-weight-bold);
	}

	.form-group {
		padding: 10px 16px;
		transition: all 0.2s ease-in-out;

		&:hover {
			background: $gray-200;
		}

		label {
			@include font-source(14px, $gray-800, $font-weight-semibold);
			margin-bottom: 5px;
		}
	}
}

.card .activeCard {
	border-left: 4px solid $gray-400 !important;
}

.activeCard .button-primary {
	background-color: #475da7;
	color: $white;
}

.card {
	border: 0 !important;
	border-left: 4px solid #fff !important;
	border-radius: 0 !important;
	background-color: $white !important;
	border-bottom: 1px solid $background !important;
}

.card-header {
	border-radius: 0 !important;
	background-color: $white !important;
	border: 0 !important;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	padding: 16px 32px 16px 20px !important;
	transition: linear padding 250ms;
	cursor: pointer;
}

.disabled {
	pointer-events: none;
	opacity: 0.5;
}

.card-header.active {
	padding-bottom: 1px !important;
	opacity: 1 !important;
}

.card-body {
	padding: 8px 32px 32px 64px !important;
}

.stepNumber {
	width: 32px;
	height: 32px;
	border: solid 2px $gray-400;
	border-radius: 100%;
	display: flex;
	flex-flow: column nowrap !important;
	justify-content: center;
	align-items: center;
	margin-right: 12px;
}

.stepNumber.active {
	border: solid 2px $teal;
}

.stepNumber.completed {
	background-color: $teal;
	border: solid 2px $teal;
}

#messageLink,
#howToRequestAccessLink,
#approvedResearcherLink,
#infoGovernanceLink,
#dsptLink {
	color: $purple !important;
	text-decoration: underline !important;
}

.panConfirm {
	text-align: right;
	margin-top: 16px;
}

.panConfirm > button {
	height: 40px;
	width: 85px;
}

.form-group .addFormInputTypeAhead {
	height: min-content !important;
	min-height: 40px;
}

.addFormInputTypeAhead .dropdown-item.active,
.addFormInputTypeAhead .dropdown-item:active {
	background-color: $gray-light;
}

.dar-form-check-label {
	padding-left: 8px;
	margin-bottom: 0;
}

.dar-form-check-group {
	display: flex;
	flex-flow: row;
	align-items: center;
	height: 24px;
}

.dar-form-check-group > button {
	margin-right: 32px;
}

.dar-form-check {
	height: 24px;
	width: 24px;
}

.checkSvg {
	fill: #ffffff !important;
	width: 100px;
}

input[type='checkbox']:after {
	width: 24px;
	height: 24px;
	border: solid 2px #d6d6d6;
	background-color: $white;
	content: '';
	display: inline-block;
	visibility: visible;
	border: solid 2px #d6d6d6;
}

.dar-form-check-group input[type='checkbox']:checked:after {
	width: 24px;
	height: 24px;
	border: solid 2px #d6d6d6;
	background-color: $white;
	position: relative;
	background-image: url(../../images/tick.svg);
	background-repeat: no-repeat;
	background-size: 21px 21px;
	display: inline-block;
	visibility: visible;
	border: solid 2px #d6d6d6;
	content: '';
}

.makeADecisionDropdown {
	position: relative;
    width: 320px !important;
    max-height: 314px !important;	
    box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.09), -3px -3px 4px 0 rgba(0, 0, 0, 0.03) !important;
    background-color: $white !important;
		padding: 12px 16px 12px 16px !important;

	option {
		padding: 4px 0;
	}
}

.makeADecisionHeader {
	position: sticky;
	top: 0;
	background-color: $white;
	z-index: 1;
	padding-left: 16px;
	padding-right: 16px;

}

.review-phase {
	margin-bottom: 20px;
	position: relative;

	option {
		padding: 4px 0;
	}

	&:after {
		position: absolute;
    bottom: -8px;
    left: -15px;
    height: 1px;
		background: $gray-pale;
		width: 316px;
		z-index: 1000;
		content: '';
	}
}