@import './variables';
/**
 * [Global Mixins]
 * 
 * - Fonts
 * - Media Queries
 * - Border Radius
 * - Retina Images
 */

// Fonts

/*
Example
    .dark-14 {
        @include font-source(14px, $indigo, 500, false, 0.5);
    }
*/
@mixin font-source($size: false, $colour: false, $weight: false,  $lh: false, $opacity: false) {
    font-family: $heading-font-family;
    @if $size { font-size: $size !important; }
    @if $colour { color: $colour !important; }
    @if $weight { font-weight: $weight; }
    @if $lh { line-height: $lh; }
    @if $opacity { opacity: $opacity; }
}


// Media Query

// Example
 /*.site-header {
     padding: 2rem;
     font-size: 1.8rem;
     @include mq('tablet-wide') {
         padding-top: 4rem;
         font-size: 2.4rem;
     }
 }*/
 

$breakpoints: (
    "phone":        320px,
    "phone-wide":   480px,
    "phablet":      560px,
    "tablet-small": 640px,
    "tablet":       768px,
    "tablet-wide":  1024px,
    "desktop":      1248px,
    "desktop-wide": 1440px
);
@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}

/*
	border radius
	example:
	.element {
		@include rounded(20px)
	}
*/

@mixin rounded($border) {
	-webkit-border-radius: $border;
	-moz-border-radius: $border;
	border-radius: $border;
}


/* 
	Retina images
	example:
	.element {
		@include retina {
			background-image: url(../img/background@2x.png);
		}
	}
*/

@mixin retina {
	@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
	only screen and (-moz-min-device-pixel-ratio: 1.5),
	only screen and (-o-min-device-pixel-ratio: 3 / 2),
	only screen and (min-device-pixel-ratio: 1.5),
	only screen and (min-resolution: 1.5dppx) {
		@content;
	}
}

// Mozilla: don't show border around the button's text on click button
button::-moz-focus-inner {
	border: none;
}

input {
	&[type="reset"]::-moz-focus-inner, &[type="button"]::-moz-focus-inner, &[type="submit"]::-moz-focus-inner, &[type="file"] > input[type="button"]::-moz-focus-inner {
		border: none;
	}
}