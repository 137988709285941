@import '../../css/variables';

.landingBackground {
    width: 100%;
    min-height: 100vh;
    background-image: linear-gradient(110deg, #50b996, #485ea7 100%);
    padding-bottom: 50px;
  }

.login-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  max-width: 540px;
}

.login-body {
  text-align: left;
  padding: 2px 16px;
}