@import '../../../css/variables';
@import '../../../css/mixins';

.resource-card-row {
    margin-top: 8px;
  }

.collection-rectangle {
  width: 100%;
  box-shadow: 1px 1px 3px 0 #00000017;
  background-color: $white;
  padding: 24px 0 8px 0;
}

.collection-rectangleWithBorder {
    width: 100%;
    background-color: #ffffff;
    padding: 24px 0 24px 0;
    border: solid 1px #e2e2e2;
  }

.buttonSvg {
  width: 10px;
  height: 10px;
}

.resultsCardInput {
    width: 100%;
    height: 40px;
    border: solid 2px $gray-400;
    background-color: $white;
    @include rounded(4px);
  }
  
  .resultsCardInput:focus {
    width: 100%;
    height: 40px;
    border: solid 2px $teal !important;
    outline: none;
    @include rounded(4px);
  }

  .relationshipBar {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #d8d8d8;
  line-height: 0.1em;
  margin: 24px 0 8px;
}

.relationshipBar span {
  font-family: museo-sans-rounded;
  font-size: 13px;
  color: #848e97 !important;
  background: #fff;
  padding: 0 10px;
}

.relationshipAnswer {
      width: 100%;
      background-color: #f6f7f8;
      padding: 16px;
      @include rounded(8px);
    }

.collection-card { 
  color: $gray-700;
  font-size: 13px;
  font-weight: 500;
  &-user {
    @extend .collection-card;
  }
  &-updated {
    @extend .collection-card;
    float:right;
  }
}

.calendarSVG {
  transform: translate(0px, 5px);
}

.datasetLogoCircle {
  width: 48px;
  height: 48px;
  padding: 4px;
  border: solid 2px $gray-300;
  background-color: #ffffff; 
  border-radius: 50%;
}