

.time {
  display: flex;
  flex-flow: row;
  align-items: center;

  svg {
    margin-right: 5px;
  }
}