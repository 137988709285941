@import '../../../css/variables';

.app-red {
  color: $red;
}

.activePhase {
  box-sizing: border-box;

  .modal-dialog {
    max-width: 620px;
    width: 100%;
  }

  .modal-content {
    border-radius: 0;
  }
  &-head {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    position: relative;
    padding: 32px 32px 16px 32px;

    p {
      margin-bottom: 0;
      width: 95%;
    }


    &--close {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 16px;
        height: 16px;
        fill: $indigo;
        &:hover {
            cursor: pointer;
        }
    }

  }

  &-body {
    padding: 16px 32px;
    display: grid;
    grid-template-columns: 135px 1fr;
  }
  
  &-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    padding: 16px 32px;

    button:first-child {
      margin-right: 10px;
    }
  }

}

.meta {
  padding-bottom: 8px;
}

